* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-container {
  font-size: 16px;
  height: calc(100% - 42px) !important;
}

.ql-editor {
  height: 100%;
  max-height: none;
}

.ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ql-editor img {
  max-width: 100%;
  height: auto;
}

.ql-snow .ql-tooltip {
  z-index: 1000;
}
